import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ShareButtons from "../components/share-buttons"
import Breadcrumb from '../components/breadcrumbs'

class BlogPageTemplate extends React.Component {
    render() {
        const blogpage = this.props.data.contentfulBlog
        const image = getImage(blogpage.image)
        const url = "https://www.pyxeldev.com" + blogpage.slug
        let keywords = [blogpage.category]
        let bttnTitle = "Sacramento " + blogpage.category
        let isBlogPost = true
        return (
            <Layout>
                <Seo keywords={keywords} title={blogpage.title} description={blogpage.description} location={blogpage.slug} blogpage={blogpage} isBlogPost={isBlogPost} type="BlogPosting" />
                <div className="bg-pyxelGray">
                    <div className="bg-white border border-gray-200 shadow-lg pt-breadcrumbs">
                        <nav className="flex container mx-auto px-4 py-3" aria-label="Breadcrumb">
                            <Breadcrumb links={[
                                { to: '/', label: 'Home' },
                                { to: '/blog', label: 'Blog' },
                                { to: blogpage.slug, label: blogpage.title },
                            ]} />
                        </nav>
                    </div>
                    <div className="container mx-auto px-5 lg:px-10 pt-10 lg:pt-16 pb-4 lg:pb-10">
                        <div className="max-w-4xl xl:mx-auto 2xl:ml-blogHeading pb-4">
                            <p className="text-gray-500 text-sm lg:text-lg uppercase">{blogpage.category}</p>
                            <h1 className="py-2 font-bold text-2xl md:text-3xl lg:text-4xl">{blogpage.title}</h1>
                            <p className="font-light">By {blogpage.author} - {blogpage.date}</p>
                        </div>
                        <div className="flex justify-center">
                            <GatsbyImage className="w-full xl:w-9/12 2xl:w-blog shadow-lg rounded-lg z-0" src="" image={image} title={blogpage.image.title} alt={blogpage.image.description} />
                        </div>
                        <div className="max-w-4xl mx-auto bg-white mt-4 px-5 rounded-lg shadow-lg">
                            <div className="flex mx-auto">
                                <span className="py-2 px-2 text-sm ">Share This Article:</span>
                                <div className="pt-2">
                                    <ShareButtons url={url} description={blogpage.description} title={blogpage.title} />
                                </div>
                            </div>
                        </div>
                        <div className="pt-10 max-w-4xl mx-auto" dangerouslySetInnerHTML={{
                            __html: this.props.data.contentfulBlog.body.childMarkdownRemark.html,
                        }}>
                        </div>

                        <p className="text-center font-bold mx-auto pt-7">Topics:</p>
                        <div className="text-center pt-5 pb-8 mx-auto justify-center grid gap-y-4 md:flex md:inline md:gap-x-5">
                            <Link to={blogpage.buttonSlug} title={bttnTitle} alt={bttnTitle}>
                                <button className="h-9 px-4 text-sm lg:h-10 lg:px-6 transition-colors bg-black rounded-lg border border-black focus:shadow-outline hover:bg-transparent text-white hover:text-black">
                                    <span>{blogpage.category}</span>
                                </button>
                            </Link>
                            <Link to="/contact" title="Contact Pyxel Development" alt="Contact Pyxel Development">
                                <button className="h-9 px-4 text-sm lg:h-10 lg:px-6 transition-colors bg-black rounded-lg border border-black focus:shadow-outline hover:bg-transparent text-white hover:text-black">
                                    <span>Contact Us</span>
                                </button>
                            </Link>
                            <Link to="/" title="Visit Pyxel Development" alt="Visit Pyxel Development">
                                <button className="h-9 px-4 text-sm lg:h-10 lg:px-6 transition-colors bg-black rounded-lg border border-black focus:shadow-outline hover:bg-transparent text-white hover:text-black">
                                    <span>Visit Our Website</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}
export default BlogPageTemplate
export const pageQuery = graphql`
query ContentfulBlogBySlug($slug: String !) {
    site {
        siteMetadata {
            title
        }
    }
    contentfulBlog( slug: {eq: $slug }) {
        title
        category
        slug
        author
        buttonSlug
        body {
            childMarkdownRemark {
                html
            }
         }
        description
        date(formatString: "MMMM Do, YYYY")
        dateModified(formatString: "MMMM Do, YYYY")
        image {
             gatsbyImageData (
                width: 600
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
                quality: 100
                )
                title
                description
             }
    
    }
}
`